import '../styles/global.css';
import '../fonts/serif-font.css';
import '../fonts/sans-serif-font.css';
import React from 'react';
import { PageProps } from 'gatsby';
import { SkipNavContent, SkipNavLink } from '@reach/skip-nav';
import classNames from 'classnames';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';
import '@reach/skip-nav/styles.css';
import 'focus-visible/dist/focus-visible';
import GridContextProvider from '../components/primitives/grid/context';
import JsonLd from '../components/json-ld';

const Layout = ({ children }: PageProps): React.ReactElement => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <GridContextProvider
            maxWidth={1536}
            breakpoints={{
                base: { query: '@media screen', columns: 4, gap: 16 },
                sm: { query: '@media (min-width: 640px)', columns: 4, gap: 16 },
                md: { query: '@media (min-width: 768px)', columns: 4, gap: 16 },
                lg: { query: '@media (min-width: 1024px)', columns: 12, gap: 32 },
                xl: { query: '@media (min-width: 1536px)', columns: 12, gap: 32 },
                '2xl': { query: '@media (min-width: 1536px)', columns: 12, gap: 32 },
            }}
            desktopFirst={false}
        >
            <SkipNavLink contentId="skip-nav">
                Skip to content
            </SkipNavLink>
            <JsonLd>
                {{
                    '@context': 'https://schema.org',
                    '@type': 'Corporation',
                    name: 'Prepared',
                    url: 'https://www.prepared.studio/',
                    logo: 'https://www.prepared.studio/icon.png',
                    contactPoint: {
                        '@type': 'ContactPoint',
                        email: 'hallo@prepared.studio',
                        contactType: 'customer service',
                        areaServed: ['DE', 'AT'],
                        availableLanguage: ['German'],
                    },
                }}
            </JsonLd>
            <JsonLd>
                {{
                    '@context': 'https://schema.org/',
                    '@type': 'WebSite',
                    name: 'Prepared',
                    url: 'https://www.prepared.studio/',
                }}
            </JsonLd>
            <div className={classNames(
                'bg-white min-h-screen relative z-10',
            )}
            >
                <Sidebar open={open} setOpen={setOpen} />
                <SkipNavContent className="w-full" id="skip-nav" as="main">
                    <div className="bg-white w-full">
                        {children}
                    </div>
                </SkipNavContent>
            </div>
            <Footer />
        </GridContextProvider>
    );
};

export default Layout;
